import { Chart } from "../../../types";
import { ChartRoot } from "../types";

export const CHART_HEIGHT = 640;
export const CHART_TITLE_HEIGHT = 36;
export const CHART_HEIGHT_IN_BOARD = 320;
export const CHART_MARGIN_TOP = 32;
export const CHART_MARGIN_TOP_IN_BOARD = 32;
export const CHART_MARGIN_BOTTOM = 8;
export const BOARD_HEADER_HEIGHT = 56;

export const CHART_COMPOSITION_MASTER: ChartRoot = {
  id: "root",
  type: "root",
  children: [
    {
      id: "gender_pay_gap",
      type: "group",
      children: [{ id: "gender_pay_gap", type: "chart" }],
    },
    {
      id: "female_manager_ratio",
      type: "group",
      children: [{ id: "female_manager_ratio", type: "chart" }],
    },
    {
      id: "male_childcare_leave_rate",
      type: "group",
      children: [
        { id: "male_childcare_leave_rate", type: "chart" },
        { id: "male_childcare_leave_rate_by_employee_type", type: "chart" },
        { id: "male_childcare_leave_or_childcare_purpose_leave_rate", type: "chart" },
      ],
    },
    {
      id: "gender_composition",
      type: "group",
      children: [
        { id: "gender_composition_of_all_employee", type: "chart" },
        { id: "gender_composition_of_regular_employee", type: "chart" },
        { id: "gender_composition_of_non_regular_employee", type: "chart" },
        {
          id: "gender_composition_of_all_employee_at_male_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_all_employee_at_male_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_all_employee_at_female_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_all_employee_at_female_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_all_employee_at_male_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_all_employee_at_male_ratio_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_all_employee_at_female_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_all_employee_at_female_ratio_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_regular_employee_at_male_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_regular_employee_at_male_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_regular_employee_at_female_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_regular_employee_at_female_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_regular_employee_at_male_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_regular_employee_at_male_ratio_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_regular_employee_at_female_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_regular_employee_at_female_ratio_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_non_regular_employee_at_male_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_non_regular_employee_at_male_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_non_regular_employee_at_female_headcount",
          type: "group",
          children: [
            {
              id: "gender_composition_of_non_regular_employee_at_female_headcount_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_non_regular_employee_at_male_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_non_regular_employee_at_male_ratio_by_company",
              type: "chart",
            },
          ],
        },
        {
          id: "gender_composition_of_non_regular_employee_at_female_ratio",
          type: "group",
          children: [
            {
              id: "gender_composition_of_non_regular_employee_at_female_ratio_by_company",
              type: "chart",
            },
          ],
        },
      ],
    },
    {
      id: "employee_composition",
      type: "group",
      children: [
        { id: "full_time_employee_headcount_by_gender", type: "chart" },
        { id: "full_time_employee_headcount_by_employee_type", type: "chart" },
      ],
    },
    {
      id: "span_of_control",
      type: "group",
      children: [
        { id: "span_of_control", type: "chart" },
        { id: "manager_rate", type: "chart" },
        { id: "province_span_of_control_of_executive", type: "chart" },
        { id: "province_span_of_control_of_executive_officer", type: "chart" },
        { id: "province_span_of_control_of_general_manager", type: "chart" },
        { id: "province_span_of_control_of_division_manager", type: "chart" },
        { id: "province_span_of_control_of_section_manager", type: "chart" },
        { id: "direct_span_of_control_of_executive", type: "chart" },
        { id: "direct_span_of_control_of_executive_officer", type: "chart" },
        { id: "direct_span_of_control_of_general_manager", type: "chart" },
        { id: "direct_span_of_control_of_division_manager", type: "chart" },
        { id: "direct_span_of_control_of_section_manager", type: "chart" },
      ],
    },
    {
      id: "average_age",
      type: "group",
      children: [
        { id: "average_age_of_all_employee_by_gender", type: "chart" },
        { id: "average_age_of_regular_employee_by_gender", type: "chart" },
        { id: "average_age_of_executive_officer_by_gender", type: "chart" },
        { id: "average_age_of_general_manager_by_gender", type: "chart" },
        { id: "average_age_of_division_manager_by_gender", type: "chart" },
        { id: "average_age_of_section_manager_by_gender", type: "chart" },
        { id: "average_age_of_team_leader_by_gender", type: "chart" },
        { id: "average_age_of_staff_by_gender", type: "chart" },
        { id: "average_age_of_manager_by_gender", type: "chart" },
      ],
    },
    {
      id: "age_distribution",
      type: "group",
      children: [
        { id: "headcount_age_distribution_of_regular_employee_by_gender", type: "chart" },
        { id: "ratio_age_distribution_of_regular_employee_by_gender", type: "chart" },
        { id: "headcount_age_distribution_of_manager_by_gender", type: "chart" },
        { id: "ratio_age_distribution_of_manager_by_gender", type: "chart" },
      ],
    },
    {
      id: "service_year",
      type: "group",
      children: [
        { id: "average_service_year_of_all_employee_by_gender", type: "chart" },
        { id: "average_service_year_of_regular_employee_by_gender", type: "chart" },
        { id: "average_service_year_of_non_regular_employee_by_gender", type: "chart" },
        { id: "full_time_employee_average_service_year_by_gender", type: "chart" },
        { id: "gender_average_service_year_gap_of_all_employee", type: "chart" },
        { id: "gender_average_service_year_gap_of_regular_employee", type: "chart" },
        { id: "gender_average_service_year_gap_of_non_regular_employee", type: "chart" },
        { id: "full_time_employee_gender_average_service_year_gap", type: "chart" },
      ],
    },
    {
      id: "employed_retention_rate",
      type: "group",
      children: [
        { id: "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender", type: "chart" },
        { id: "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender", type: "chart" },
        { id: "annual_mid_career_employed_three_year_retention_rate_by_gender", type: "chart" },
        { id: "annual_mid_career_employed_five_year_retention_rate_by_gender", type: "chart" },
      ],
    },
    {
      id: "annual_employed_composition",
      type: "group",
      children: [
        { id: "annual_employed_composition_of_all_employee_by_gender", type: "chart" },
        { id: "annual_employed_composition_of_regular_employee_by_gender", type: "chart" },
        { id: "annual_employed_composition_of_non_regular_employee_by_gender", type: "chart" },
        { id: "annual_employed_composition_of_regular_employee_by_employment_type", type: "chart" },
        { id: "annual_new_graduate_employed_composition_of_regular_employee_by_gender", type: "chart" },
        { id: "annual_mid_career_employed_composition_of_regular_employee_by_gender", type: "chart" },
      ],
    },
    {
      id: "employment_age_distribution",
      type: "group",
      children: [
        { id: "employment_headcount_age_distribution_of_regular_employee_by_gender", type: "chart" },
        { id: "employment_ratio_age_distribution_of_regular_employee_by_gender", type: "chart" },
      ],
    },
    {
      id: "mid_career_employed",
      type: "group",
      children: [
        { id: "mid_career_employed_headcount_and_rate_of_all_employee", type: "chart" },
        { id: "mid_career_employed_headcount_and_rate_of_manager", type: "chart" },
        { id: "mid_career_employed_executive_headcount_and_rate", type: "chart" },
      ],
    },
    {
      id: "separated",
      type: "group",
      children: [
        { id: "separated_headcount_and_rate_of_regular_employee_by_gender", type: "chart" },
        { id: "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender", type: "chart" },
      ],
    },
    {
      id: "executive_composition",
      type: "group",
      children: [{ id: "executive_gender_composition", type: "chart" }],
    },
    {
      id: "female_headcount_and_rate_by_grade",
      type: "group",
      children: [
        { id: "female_headcount_and_rate_of_division_manager", type: "chart" },
        { id: "female_headcount_and_rate_of_section_manager", type: "chart" },
        { id: "female_headcount_and_rate_of_team_leader", type: "chart" },
      ],
    },
    {
      id: "childcare_leave",
      type: "group",
      children: [
        { id: "childcare_leave_headcount_and_rate_of_all_employee_by_gender", type: "chart" },
        { id: "childcare_leave_headcount_and_rate_of_regular_employee_by_gender", type: "chart" },
        { id: "childcare_leave_headcount_and_rate_of_non_regular_employee_by_gender", type: "chart" },
        { id: "male_childcare_leave_or_childcare_purpose_leave_rate", type: "chart" },
        { id: "average_childcare_leave_taken_days_of_all_employee_by_gender", type: "chart" },
        { id: "average_childcare_leave_taken_days_of_regular_employee_by_gender", type: "chart" },
        { id: "average_childcare_leave_taken_days_of_non_regular_employee_by_gender", type: "chart" },
        { id: "male_childcare_leave_taken_day_distribution", type: "chart" },
      ],
    },
    {
      id: "foreign_employee",
      type: "group",
      children: [
        { id: "foreign_employee_headcount_by_nationality", type: "chart" },
        { id: "foreign_employee_headcount_by_gender", type: "chart" },
        { id: "foreign_employee_headcount_and_rate_of_all_employee", type: "chart" },
        { id: "foreign_employee_headcount_and_rate_of_manager", type: "chart" },
        { id: "foreign_new_graduate_employed_headcount_and_rate", type: "chart" },
        { id: "foreign_executive_headcount_and_rate", type: "chart" },
      ],
    },
    {
      id: "disabled_employment",
      type: "group",
      children: [
        { id: "disabled_employment_headcount_and_rate", type: "chart" },
        { id: "disabled_employment_shortage_headcount", type: "chart" },
      ],
    },
    {
      id: "actual_working_hours",
      type: "group",
      children: [
        { id: "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender", type: "chart" },
        { id: "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender", type: "chart" },
      ],
    },
    {
      id: "over_scheduled_working_hours",
      type: "group",
      children: [
        { id: "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee", type: "chart" },
        { id: "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager", type: "chart" },
        { id: "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager", type: "chart" },
        { id: "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee", type: "chart" },
        { id: "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager", type: "chart" },
        { id: "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager", type: "chart" },
      ],
    },
    {
      id: "paid_leave_consumed_days",
      type: "group",
      children: [
        { id: "average_annual_paid_leave_consumed_days_and_rate_of_all_employee", type: "chart" },
        { id: "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee", type: "chart" },
        { id: "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee", type: "chart" },
      ],
    },
    {
      id: "employees_using_childcare_purpose_attendance_system",
      type: "group",
      children: [
        { id: "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee", type: "chart" },
        { id: "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee", type: "chart" },
        { id: "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee", type: "chart" },
        { id: "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee", type: "chart" },
        { id: "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee", type: "chart" },
        { id: "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee", type: "chart" },
      ],
    },
    {
      id: "familycare_leave",
      type: "group",
      children: [{ id: "familycare_leave_headcount_by_gender", type: "chart" }],
    },
    {
      id: "annual_salary",
      type: "group",
      children: [{ id: "average_annual_salary_of_regular_employee_by_gender", type: "chart" }],
    },
    {
      id: "annual_bonus",
      type: "group",
      children: [
        { id: "average_annual_bonus_of_regular_employee_by_gender", type: "chart" },
        { id: "average_annual_bonus_of_regular_employee_by_grade_type", type: "chart" },
      ],
    },
    {
      id: "salary_increase_rate",
      type: "group",
      children: [{ id: "salary_increase_rate_of_regular_employee_by_management_type", type: "chart" }],
    },
    {
      id: "discipline",
      type: "group",
      children: [{ id: "discipline_count_by_discipline_type", type: "chart" }],
    },
  ],
} as const;

/*
 * 指標で利用するTYPES
 */
export const INDIVIDUAL_LIST_TYPE_ALL = "all" as const;
export const AGGREGATE_TYPES = ["our_company", "industry_average", "all_industry_average"] as const;
// 下記のリストは、[male, female, all]、[regular, non_regular, all]の順で定義すること
// また利用時は以下のルールにしたがって利用すること
// 表の場合: リストをそのままの順番で利用
// 折れ線グラフ: リストをそのままの順番で利用
// 積み上げグラフ: リストを逆順にして利用（ex: EMPLOYEE_TYPES.toReversed()）
// 積み上げグラフ+折れ線グラフ: リストを逆順にして利用（ex: EMPLOYEE_TYPES.toReversed()）
export const EMPLOYEE_TYPES = ["regular", "non_regular", "all"] as const;
export const EMPLOYMENT_TYPES = ["new_graduate", "mid_career", "other", "all"] as const;
export const ANNUAL_EMPLOYED_COMPOSITION_HEADCOUNT_TYPES = [
  "new_graduate_headcount",
  "mid_career_headcount",
  "other_headcount",
  "all_headcount",
] as const;
export const ANNUAL_EMPLOYED_COMPOSITION_RATIO_TYPES = [
  "new_graduate_ratio",
  "mid_career_ratio",
  "other_ratio",
] as const;
export const BINARY_GENDER_TYPES = ["male", "female"] as const;
export const GENDER_TYPES = [...BINARY_GENDER_TYPES, "all"] as const;
export const BINARY_GENDER_HEADCOUNT_TYPES = ["male_headcount", "female_headcount"] as const;
export const GENDER_HEADCOUNT_TYPES = [...BINARY_GENDER_HEADCOUNT_TYPES, "all_headcount"] as const;
export const GENDER_RATIO_TYPES = ["male_ratio", "female_ratio"] as const;
export const GENDER_RATE_TYPES = ["male_rate", "female_rate", "all_rate"] as const;
export const GRADE_TYPES = [
  "executive_officer",
  "general_manager",
  "division_manager",
  "section_manager",
  "team_leader",
  "staff",
] as const;
export const EXACT_GRADE_OR_HIGHER_TYPES = ["exact", "higher"] as const;
export const MANAGEMENT_TYPES = ["manager", "non_manager", "all"] as const;

export const PHYSICAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "severe_physical_disabled_employee_working_over_30h_headcount",
  "new_employed_severe_physical_disabled_employee_working_over_30h_headcount",
  "non_severe_physical_disabled_employee_working_over_30h_headcount",
  "new_employed_non_severe_physical_disabled_employee_working_over_30h_headcount",
  "severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "severe_physical_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_severe_physical_disabled_employee_working_10h_to_20h_headcount",
  "non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
  "physical_disabled_employee_headcount",
  "new_employed_physical_disabled_employee_headcount",
];

export const INTELLECTUAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "severe_intellectual_disabled_employee_working_over_30h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "non_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "new_employed_non_severe_intellectual_disabled_employee_working_over_30h_headcount",
  "severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
  "non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
  "intellectual_disabled_employee_headcount",
  "new_employed_intellectual_disabled_employee_headcount",
];

export const MENTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "mental_disabled_employee_working_over_30h_headcount",
  "new_employed_mental_disabled_employee_working_over_30h_headcount",
  "mental_disabled_employee_working_20h_to_30h_headcount",
  "new_employed_mental_disabled_employee_working_20h_to_30h_headcount",
  "mental_disabled_employee_working_10h_to_20h_headcount",
  "new_employed_mental_disabled_employee_working_10h_to_20h_headcount",
  "mental_disabled_employee_headcount",
  "new_employed_mental_disabled_employee_headcount",
] as const;

export const OTHER_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "visual_disabled_employee_headcount",
  "auditory_balance_disabled_employee_headcount",
  "speech_language_mastication_disabled_employee_headcount",
  "physical_impaired_employee_headcount",
  "internal_disabled_employee_headcount",
] as const;

export const TOTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES = [
  "disabled_employee_headcount",
  "new_employed_disabled_employee_headcount",
];

export const SINGLE_YEAR_CHARTS = [
  "headcount_age_distribution_of_regular_employee_by_gender",
  "ratio_age_distribution_of_regular_employee_by_gender",
  "headcount_age_distribution_of_manager_by_gender",
  "ratio_age_distribution_of_manager_by_gender",
  "male_childcare_leave_taken_day_distribution",
  "employment_headcount_age_distribution_of_regular_employee_by_gender",
  "employment_ratio_age_distribution_of_regular_employee_by_gender",
];

export const NO_FORMULA_CHARTS = [
  "full_time_employee_headcount_by_gender",
  "full_time_employee_headcount_by_employee_type",
  "headcount_age_distribution_of_regular_employee_by_gender",
  "headcount_age_distribution_of_manager_by_gender",
  "foreign_employee_headcount_by_nationality",
  "foreign_employee_headcount_by_gender",
  "discipline_count_by_discipline_type",
  "familycare_leave_headcount_by_gender",
  "employment_headcount_age_distribution_of_regular_employee_by_gender",
];

export const DISABLED_EMPLOYMENT_RATE_TYPES = ["disabled_employment_rate"] as const;
export const DISCIPLINE_TYPES = [
  "disciplinary_dismissal",
  "recommended_retirement",
  "demotion",
  "suspension_from_work",
  "pay_cut",
  "reprimand",
  "warning",
  "all",
] as const;

// 在籍会社のみを表示するチャート
export const COMPANY_TYPE_FILTER_ONLY_CURRENT_COMPANY_CHART_LIST: Chart[] = [
  "manager_rate",
  "span_of_control",
  "province_span_of_control_of_executive",
  "province_span_of_control_of_executive_officer",
  "province_span_of_control_of_general_manager",
  "province_span_of_control_of_division_manager",
  "province_span_of_control_of_section_manager",
  "direct_span_of_control_of_executive",
  "direct_span_of_control_of_executive_officer",
  "direct_span_of_control_of_general_manager",
  "direct_span_of_control_of_division_manager",
  "direct_span_of_control_of_section_manager",
];

/*
 * 算出根拠で利用するTYPES
 */
export const EMPLOYEE_GENDER_TYPES = [
  "regular_male",
  "regular_female",
  "non_regular_male",
  "non_regular_female",
  "all_male",
  "all_female",
] as const;

export const OUR_COMPANY_COLORS = ["#007CC0", "#008ED4", "#04A1E8", "#08AFF7", "#52C8F8", "#83D8FB", "#B4E7FC"];
export const INDUSTRY_AVERAGE_COLORS = ["#E45327", "#F25A2C", "#FD6030", "#FD774D", "#FD8F6D", "#FDAE97", "#FDCEBF"];
export const ALL_INDUSTRY_AVERAGE_COLORS = [
  "#FB9D15",
  "#FCAF16",
  "#FCBD1B",
  "#FDC630",
  "#FED253",
  "#FEDE84",
  "#FEEBB4",
];
export const OUR_COMPANY_LINE_WITH_COLUMN_COLORS = ["#95CEFF", "#BCE0FF", "#E4F3FE"];
export const INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS = ["#FFA88C", "#FFC9B8", "#FAE1D9"];
export const ALL_INDUSTRY_AVERAGE_LINE_WITH_COLUMN_COLORS = ["#FFE0B1", "#FFF0D9", "#F2E6D5"];
