// ワークスペースのスラグがURLになるので、アプリで利用するパスを予約語として定義し、スラグとして登録できないようにする
export const RESERVED_SLUGS = [
  "workspaces",
  "members",
  "companies",
  "business-years",
  "settings",
  "industry-average-sources",
  "my-workspaces",
  "boards",
  "charts",
];
export const PRESET_BOARD_IDS = [
  "mandatory_three_indicators",
  "employee_basic_information",
  "mobility",
  "diversity",
  "work_style",
  "salary",
  "compliance",
];
export const SEPARATOR = "@@@";
export const SLUG_PATTERN = /^[a-z0-9-]*$/;
export const CHARACTER_LIMIT = {
  "80": 80,
} as const;
export const NONE = "-";
export const REFETCH_INTERVAL = 1000 * 30;
export const STALE_TIME = 1000 * 60;
export const WORKSPACE_ID_HEADER_NAME = "x-hci-workspace-id";
export const COMPANY_TYPES = ["original", "current"] as const;
export const WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL =
  "https://positive-ryouritsu.mhlw.go.jp/positivedb/opendata/";
export const WOMEN_S_PARTICIPATION_AND_ADVANCEMENT_DATABASE_URL_REFERENCE_DATE = "2023-07-24";
export const DATA_GRID_PAGE_SIZE = 20;
export const JAPAN_STANDARD_INDUSTRIAL_CLASSIFICATION_URL =
  "https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000044.html#a";

export const CHART_GROUPS = [
  "gender_pay_gap",
  "female_manager_ratio",
  "male_childcare_leave_rate",
  "gender_composition",
  "employee_composition",
  "span_of_control",
  "average_age",
  "age_distribution",
  "service_year",
  "employed_retention_rate",
  "annual_employed_composition",
  "employment_age_distribution",
  "mid_career_employed",
  "separated",
  "executive_composition",
  "female_headcount_and_rate_by_grade",
  "childcare_leave",
  "foreign_employee",
  "disabled_employment",
  "actual_working_hours",
  "over_scheduled_working_hours",
  "paid_leave_consumed_days",
  "employees_using_childcare_purpose_attendance_system",
  "familycare_leave",
  "annual_salary",
  "annual_bonus",
  "salary_increase_rate",
  "discipline",
] as const;

export const SEGMENT_CHART_GROUPS = [
  "gender_composition_of_all_employee_at_male_headcount",
  "gender_composition_of_all_employee_at_female_headcount",
  "gender_composition_of_all_employee_at_male_ratio",
  "gender_composition_of_all_employee_at_female_ratio",
  "gender_composition_of_regular_employee_at_male_headcount",
  "gender_composition_of_regular_employee_at_female_headcount",
  "gender_composition_of_regular_employee_at_male_ratio",
  "gender_composition_of_regular_employee_at_female_ratio",
  "gender_composition_of_non_regular_employee_at_male_headcount",
  "gender_composition_of_non_regular_employee_at_female_headcount",
  "gender_composition_of_non_regular_employee_at_male_ratio",
  "gender_composition_of_non_regular_employee_at_female_ratio",
] as const;

export const CHARTS = [
  "gender_pay_gap",
  "female_manager_ratio",
  "male_childcare_leave_rate",
  "male_childcare_leave_rate_by_employee_type",
  "male_childcare_leave_or_childcare_purpose_leave_rate",
  "gender_composition_of_all_employee",
  "gender_composition_of_regular_employee",
  "gender_composition_of_non_regular_employee",
  "full_time_employee_headcount_by_gender",
  "full_time_employee_headcount_by_employee_type",
  "manager_rate",
  "span_of_control",
  "province_span_of_control_of_executive",
  "province_span_of_control_of_executive_officer",
  "province_span_of_control_of_general_manager",
  "province_span_of_control_of_division_manager",
  "province_span_of_control_of_section_manager",
  "direct_span_of_control_of_executive",
  "direct_span_of_control_of_executive_officer",
  "direct_span_of_control_of_general_manager",
  "direct_span_of_control_of_division_manager",
  "direct_span_of_control_of_section_manager",
  "average_age_of_all_employee_by_gender",
  "average_age_of_regular_employee_by_gender",
  "average_age_of_executive_officer_by_gender",
  "average_age_of_general_manager_by_gender",
  "average_age_of_division_manager_by_gender",
  "average_age_of_section_manager_by_gender",
  "average_age_of_team_leader_by_gender",
  "average_age_of_staff_by_gender",
  "average_age_of_manager_by_gender",
  "ratio_age_distribution_of_regular_employee_by_gender",
  "headcount_age_distribution_of_regular_employee_by_gender",
  "headcount_age_distribution_of_manager_by_gender",
  "ratio_age_distribution_of_manager_by_gender",
  "average_service_year_of_all_employee_by_gender",
  "average_service_year_of_regular_employee_by_gender",
  "average_service_year_of_non_regular_employee_by_gender",
  "full_time_employee_average_service_year_by_gender",
  "gender_average_service_year_gap_of_all_employee",
  "gender_average_service_year_gap_of_regular_employee",
  "gender_average_service_year_gap_of_non_regular_employee",
  "full_time_employee_gender_average_service_year_gap",
  "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender",
  "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender",
  "annual_mid_career_employed_three_year_retention_rate_by_gender",
  "annual_mid_career_employed_five_year_retention_rate_by_gender",
  "annual_employed_composition_of_all_employee_by_gender",
  "annual_employed_composition_of_regular_employee_by_gender",
  "annual_employed_composition_of_non_regular_employee_by_gender",
  "annual_employed_composition_of_regular_employee_by_employment_type",
  "annual_new_graduate_employed_composition_of_regular_employee_by_gender",
  "annual_mid_career_employed_composition_of_regular_employee_by_gender",
  "employment_headcount_age_distribution_of_regular_employee_by_gender",
  "employment_ratio_age_distribution_of_regular_employee_by_gender",
  "mid_career_employed_headcount_and_rate_of_all_employee",
  "mid_career_employed_headcount_and_rate_of_manager",
  "mid_career_employed_executive_headcount_and_rate",
  "executive_gender_composition",
  "female_headcount_and_rate_of_division_manager",
  "female_headcount_and_rate_of_section_manager",
  "female_headcount_and_rate_of_team_leader",
  "separated_headcount_and_rate_of_regular_employee_by_gender",
  "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender",
  "childcare_leave_headcount_and_rate_of_all_employee_by_gender",
  "childcare_leave_headcount_and_rate_of_regular_employee_by_gender",
  "childcare_leave_headcount_and_rate_of_non_regular_employee_by_gender",
  "average_childcare_leave_taken_days_of_all_employee_by_gender",
  "average_childcare_leave_taken_days_of_regular_employee_by_gender",
  "average_childcare_leave_taken_days_of_non_regular_employee_by_gender",
  "male_childcare_leave_taken_day_distribution",
  "foreign_employee_headcount_by_nationality",
  "foreign_employee_headcount_by_gender",
  "foreign_employee_headcount_and_rate_of_all_employee",
  "foreign_employee_headcount_and_rate_of_manager",
  "foreign_new_graduate_employed_headcount_and_rate",
  "foreign_executive_headcount_and_rate",
  "disabled_employment_headcount_and_rate",
  "disabled_employment_shortage_headcount",
  "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender",
  "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender",
  "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee",
  "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager",
  "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager",
  "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee",
  "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager",
  "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager",
  "average_annual_paid_leave_consumed_days_and_rate_of_all_employee",
  "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee",
  "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee",
  "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee",
  "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee",
  "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee",
  "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee",
  "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee",
  "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee",
  "familycare_leave_headcount_by_gender",
  "average_annual_salary_of_regular_employee_by_gender",
  "average_annual_bonus_of_regular_employee_by_gender",
  "average_annual_bonus_of_regular_employee_by_grade_type",
  "salary_increase_rate_of_regular_employee_by_management_type",
  "discipline_count_by_discipline_type",
] as const;

export const SEGMENT_CHARTS = [
  "gender_composition_of_all_employee_at_male_headcount_by_company",
  "gender_composition_of_all_employee_at_female_headcount_by_company",
  "gender_composition_of_all_employee_at_male_ratio_by_company",
  "gender_composition_of_all_employee_at_female_ratio_by_company",
  "gender_composition_of_regular_employee_at_male_headcount_by_company",
  "gender_composition_of_regular_employee_at_female_headcount_by_company",
  "gender_composition_of_regular_employee_at_male_ratio_by_company",
  "gender_composition_of_regular_employee_at_female_ratio_by_company",
  "gender_composition_of_non_regular_employee_at_male_headcount_by_company",
  "gender_composition_of_non_regular_employee_at_female_headcount_by_company",
  "gender_composition_of_non_regular_employee_at_male_ratio_by_company",
  "gender_composition_of_non_regular_employee_at_female_ratio_by_company",
] as const;
